import './assets/styles/style.less'

import 'font-awesome/less/font-awesome.less'

import { store } from './store'
import { createApp } from 'vue'
import QuickAdd from './components/quick_add.vue'
import AddressSelect from './components/address_select.vue'
import WdmAddToBasket from './components/wdm_add_to_basket.vue'
import BasketComponent from './components/basket_component.vue'
import jQuery from 'jquery'
import { carousel } from './carousel'
import * as Sentry from '@sentry/vue'
import './csrf'
import './add_to_basket'
import { unifyLinkTargetWindows } from './unify_link_targets'
import './prevent_double_submit'
import { django_i18n } from './django_i18n'

import 'bootstrap/js/tab'
import 'bootstrap/js/transition'
import 'bootstrap/js/tooltip'
import 'bootstrap/js/popover'
import 'bootstrap/js/dropdown'
import 'bootstrap/js/alert'

// polyfills
// used by babel-preset-env
import 'core-js/stable'
// required for firefox <52 :rolleyes:
import 'regenerator-runtime/runtime'

window.jQuery = jQuery
window.$ = jQuery

let app = createApp({})
app.use(store)
app.component('basket-component', BasketComponent)
app.component('quick-add', QuickAdd)
app.component('address-select', AddressSelect)
app.component('wdm-add-to-basket', WdmAddToBasket)
app.config.globalProperties.gettext = django_i18n.gettext

let dsn = document.documentElement.getAttribute('data-sentry-dsn')
let env = document.documentElement.getAttribute('data-sentry-env')
let git_hash = document.documentElement.getAttribute('data-git-hash')

Sentry.init({ app: app, dsn: dsn, environment: env, release: git_hash })

window.addEventListener('load', function () {
    if (document.getElementById('main') !== null) {
        app.mount('#main')
    }

    carousel()
    require('./userbar')
    unifyLinkTargetWindows()
    jQuery('a.toggle_active_xs').on('click', function (event) {
        event.preventDefault()
        jQuery(this).parent('li').toggleClass('active')
    })
})
